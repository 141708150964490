@import "../../shared/theme.scss";
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
@mixin paragraph-template{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    border-top: 1px solid #eff0f1;
    padding: 0 0;
    font-size: 13px;
    letter-spacing: 0.2px;
    color: #6C757D;
    line-height: 20px;
}
.about-container{
    display: flex;
    flex-direction: column;
    height: auto;
    margin-top: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 16px rgba(0, 24, 26, 0.04);
    position: relative;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 30px;
    cursor: pointer;
}
.surgeon-desc {
    @include paragraph-template();
    border-top: none;
}
.surgeon-desc-hidden{
    @include paragraph-template();
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    border-top: none;
    position: relative;
}
.surgeon-desc-hidden::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    top: 40px;
    background-color: rgba(255, 255, 255, 0.7)
}
.down-arrow{
    display: flex;
}
.up-arrow{
    transform: scaleY(-1)
}
.overflow{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.hidden{
    display: none;
}
// @media screen and (min-width: 550px) {
//     .about-container{
//         cursor: auto;
//     }
//     .surgeon-desc{
//         width: 100%;
//         padding: 0;
//     }
//     .surgeon-desc-hidden{
//         width: 100%;
//         padding: 0;
//     }
//     .surgeon-desc-hidden::after{
//         display: none;
//     }
//     // .overflow{
//     //     display: none;
//     //     .down-arrow{
//     //         display: none;
//     //     }
//     //     .up-arrow{
//     //         display: none;
//     //     }
//     // }
// }
@media screen and (min-width:768px) {
    .about-container{
        display: flex;
        flex-direction: column;
        height: auto;
        margin-top: 0;
        background-color: #FFFFFF;
        box-shadow: none;
        position: relative;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0 30px;
        cursor: auto;
    }
    .surgeon-desc{
        border-top: 1px solid #eff0f1;
        width: 100%;
        padding: 24px 0;
    }
    .overflow{
        display: none;
        .down-arrow{
            display: none;
        }
        .up-arrow{
            display: none;
        }
    }
}
