*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.contact-us{
    /* Mobile/Caption 1 - Mobile */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height, or 154% */

    text-align: center;
    letter-spacing: 0.2px;

    /* Neutral/Typography Medium */

    color: #6C757D;

    .contact-link{
        text-decoration: none;
        color: #007078;
    }
}

.expired-card-container{
    display: flex;
    background-color: #FFFFFF;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
    height: 563px;
    // border:dotted;
    max-width: 864px;
    box-shadow: 0px 4px 20px rgba(0, 12, 13, 0.08);
    border-radius: 4px;

    .new-link-requested{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px;
        width: 90%;
        background: #EBFBF2;
        border-radius: 4px;
        max-height: 98px;
        height: 100%;
        flex: 2;

        .row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
          }
          
          .column {
            display: flex;
            flex-direction: column;
            flex-basis: 100%;
            flex: 1;
          }

          .double-column {
            display: flex;
            flex-direction: column;
            align-items:flex-start;
            justify-content: center;
            flex-basis: 100%;
            flex: 3;

            .new-link-header{
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 23px;
                /* identical to box height, or 164% */
                letter-spacing: 0.2px;
                /* Neutral/Typography Dark */
                color: #373D43;
                // margin: 0px 0 -10px 0;
            }

            .new-link-message{
                margin: 0;
                /* Mobile/Caption 2 - Mobile */
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                /* or 160% */
                letter-spacing: 0.2px;
                /* Neutral/Typography Dark */
                color: #373D43;
            }

          }
        
        .approved-img-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-basis: 100%;
            flex: 1;
            width: 20%;
            img{
                max-width: 100%;
                max-height: 100%;
            }
            
        }
    }
  
    & div{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content:flex-start;
        .expired-image{
            margin-top: 60px;
            margin-bottom: 40px;
            width: 160px;
            height: 169px;
        }
        .expired-header{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 26px;
            line-height: 34px;      
            letter-spacing: 0.2px;
            color: #373D43;
            margin-bottom: 16px;
        }
        .expired-paragraph{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            /* or 157% */
            
            text-align: center;
            letter-spacing: 0.2px;
            color: #373D43;
            width: 523px;
            margin-bottom: 30px;
        }
        .expired-button{
            margin-bottom: 16px;
            /* Primary/Digital Teal Default */
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            padding: 8px 16px 8px 16px;
            width: 343px;
            height: 38px;
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            align-items: center;
            letter-spacing: 0.2px;
            background-color: #007078;
            text-transform: uppercase;
            border: none;
            outline: none;
            border-radius: 4px;
            cursor: pointer;

        }
        .expired-button:hover{
            background: #004c52;
        }
    }
}

@media screen and (max-width: 900px) {
    .expired-card-container{
        display: flex;
        background-color: #FFFFFF;
        justify-content: center;
        align-items: center;
        // margin: 50px auto;
        width: 90vw;
        // height: 70vh;
        box-shadow: 0px 4px 20px rgba(0, 12, 13, 0.08);
        border-radius: 4px;

        & div{
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 80%;
            justify-content: space-around;
            .expired-image{
                width: 90px;
                height: 95px;
            }
            .expired-header{
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 30px;
                letter-spacing: 0.2px;
                color: #373D43;
            }
            .expired-paragraph{
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: normal;
                width: 90%;
                font-size: 13px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.2px;
                color: #373D43;
            }
            .expired-button{
                // display: flex;
                // justify-content: center;
                // align-items: center;
                width: 168px;
                height: 40px;
                // font-family: 'Open Sans', sans-serif;
                // font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 22px;
                // display: flex;
                // align-items: center;
                // letter-spacing: 0.2px;
                // background-color: #E6F2F2;;
                // text-transform: uppercase;
                // color: #00767A;
                // border: none;
                // outline: none;
                // border-radius: 4px;
                // cursor: pointer;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .expired-card-container{
        margin: 80px auto;
    }
    .new-link-header{
        margin: 10px 0px -20px 0px !important;
    }
}