*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.loading{
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .loader {
        margin: 50px auto;
        font-size: 12px;
        width: 1.25em;
        height: 1.25em;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        -webkit-animation: load5 1.1s infinite ease;
        animation: load5 1.1s infinite ease;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
      }
    @-webkit-keyframes load5 {
        0%,
        100% {
          box-shadow: 0em -2.6em 0em 0em #007078, 1.8em -1.8em 0 0em rgba(0,115,119, 0.2), 2.5em 0em 0 0em rgba(0,115,119, 0.2), 1.75em 1.75em 0 0em rgba(0,115,119, 0.2), 0em 2.5em 0 0em rgba(0,115,119, 0.2), -1.8em 1.8em 0 0em rgba(0,115,119, 0.2), -2.6em 0em 0 0em rgba(0,115,119, 0.5), -1.8em -1.8em 0 0em rgba(0,115,119, 0.7);
        }
        12.5% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.7), 1.8em -1.8em 0 0em #007078, 2.5em 0em 0 0em rgba(0,115,119, 0.2), 1.75em 1.75em 0 0em rgba(0,115,119, 0.2), 0em 2.5em 0 0em rgba(0,115,119, 0.2), -1.8em 1.8em 0 0em rgba(0,115,119, 0.2), -2.6em 0em 0 0em rgba(0,115,119, 0.2), -1.8em -1.8em 0 0em rgba(0,115,119, 0.5);
        }
        25% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.5), 1.8em -1.8em 0 0em rgba(0,115,119, 0.7), 2.5em 0em 0 0em #007078, 1.75em 1.75em 0 0em rgba(0,115,119, 0.2), 0em 2.5em 0 0em rgba(0,115,119, 0.2), -1.8em 1.8em 0 0em rgba(0,115,119, 0.2), -2.6em 0em 0 0em rgba(0,115,119, 0.2), -1.8em -1.8em 0 0em rgba(0,115,119, 0.2);
        }
        37.5% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.2), 1.8em -1.8em 0 0em rgba(0,115,119, 0.5), 2.5em 0em 0 0em rgba(0,115,119, 0.7), 1.75em 1.75em 0 0em #007078, 0em 2.5em 0 0em rgba(0,115,119, 0.2), -1.8em 1.8em 0 0em rgba(0,115,119, 0.2), -2.6em 0em 0 0em rgba(0,115,119, 0.2), -1.8em -1.8em 0 0em rgba(0,115,119, 0.2);
        }
        50% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.2), 1.8em -1.8em 0 0em rgba(0,115,119, 0.2), 2.5em 0em 0 0em rgba(0,115,119, 0.5), 1.75em 1.75em 0 0em rgba(0,115,119, 0.7), 0em 2.5em 0 0em #007078, -1.8em 1.8em 0 0em rgba(0,115,119, 0.2), -2.6em 0em 0 0em rgba(0,115,119, 0.2), -1.8em -1.8em 0 0em rgba(0,115,119, 0.2);
        }
        62.5% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.2), 1.8em -1.8em 0 0em rgba(0,115,119, 0.2), 2.5em 0em 0 0em rgba(0,115,119, 0.2), 1.75em 1.75em 0 0em rgba(0,115,119, 0.5), 0em 2.5em 0 0em rgba(0,115,119, 0.7), -1.8em 1.8em 0 0em #007078, -2.6em 0em 0 0em rgba(0,115,119, 0.2), -1.8em -1.8em 0 0em rgba(0,115,119, 0.2);
        }
        75% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.2), 1.8em -1.8em 0 0em rgba(0,115,119, 0.2), 2.5em 0em 0 0em rgba(0,115,119, 0.2), 1.75em 1.75em 0 0em rgba(0,115,119, 0.2), 0em 2.5em 0 0em rgba(0,115,119, 0.5), -1.8em 1.8em 0 0em rgba(0,115,119, 0.7), -2.6em 0em 0 0em #007078, -1.8em -1.8em 0 0em rgba(0,115,119, 0.2);
        }
        87.5% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.2), 1.8em -1.8em 0 0em rgba(0,115,119, 0.2), 2.5em 0em 0 0em rgba(0,115,119, 0.2), 1.75em 1.75em 0 0em rgba(0,115,119, 0.2), 0em 2.5em 0 0em rgba(0,115,119, 0.2), -1.8em 1.8em 0 0em rgba(0,115,119, 0.5), -2.6em 0em 0 0em rgba(0,115,119, 0.7), -1.8em -1.8em 0 0em #007078;
        }
      }
      @keyframes load5 {
        0%,
        100% {
          box-shadow: 0em -2.6em 0em 0em #007078, 1.8em -1.8em 0 0em rgba(0,115,119, 0.2), 2.5em 0em 0 0em rgba(0,115,119, 0.2), 1.75em 1.75em 0 0em rgba(0,115,119, 0.2), 0em 2.5em 0 0em rgba(0,115,119, 0.2), -1.8em 1.8em 0 0em rgba(0,115,119, 0.2), -2.6em 0em 0 0em rgba(0,115,119, 0.5), -1.8em -1.8em 0 0em rgba(0,115,119, 0.7);
        }
        12.5% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.7), 1.8em -1.8em 0 0em #007078, 2.5em 0em 0 0em rgba(0,115,119, 0.2), 1.75em 1.75em 0 0em rgba(0,115,119, 0.2), 0em 2.5em 0 0em rgba(0,115,119, 0.2), -1.8em 1.8em 0 0em rgba(0,115,119, 0.2), -2.6em 0em 0 0em rgba(0,115,119, 0.2), -1.8em -1.8em 0 0em rgba(0,115,119, 0.5);
        }
        25% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.5), 1.8em -1.8em 0 0em rgba(0,115,119, 0.7), 2.5em 0em 0 0em #007078, 1.75em 1.75em 0 0em rgba(0,115,119, 0.2), 0em 2.5em 0 0em rgba(0,115,119, 0.2), -1.8em 1.8em 0 0em rgba(0,115,119, 0.2), -2.6em 0em 0 0em rgba(0,115,119, 0.2), -1.8em -1.8em 0 0em rgba(0,115,119, 0.2);
        }
        37.5% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.2), 1.8em -1.8em 0 0em rgba(0,115,119, 0.5), 2.5em 0em 0 0em rgba(0,115,119, 0.7), 1.75em 1.75em 0 0em #007078, 0em 2.5em 0 0em rgba(0,115,119, 0.2), -1.8em 1.8em 0 0em rgba(0,115,119, 0.2), -2.6em 0em 0 0em rgba(0,115,119, 0.2), -1.8em -1.8em 0 0em rgba(0,115,119, 0.2);
        }
        50% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.2), 1.8em -1.8em 0 0em rgba(0,115,119, 0.2), 2.5em 0em 0 0em rgba(0,115,119, 0.5), 1.75em 1.75em 0 0em rgba(0,115,119, 0.7), 0em 2.5em 0 0em #007078, -1.8em 1.8em 0 0em rgba(0,115,119, 0.2), -2.6em 0em 0 0em rgba(0,115,119, 0.2), -1.8em -1.8em 0 0em rgba(0,115,119, 0.2);
        }
        62.5% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.2), 1.8em -1.8em 0 0em rgba(0,115,119, 0.2), 2.5em 0em 0 0em rgba(0,115,119, 0.2), 1.75em 1.75em 0 0em rgba(0,115,119, 0.5), 0em 2.5em 0 0em rgba(0,115,119, 0.7), -1.8em 1.8em 0 0em #007078, -2.6em 0em 0 0em rgba(0,115,119, 0.2), -1.8em -1.8em 0 0em rgba(0,115,119, 0.2);
        }
        75% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.2), 1.8em -1.8em 0 0em rgba(0,115,119, 0.2), 2.5em 0em 0 0em rgba(0,115,119, 0.2), 1.75em 1.75em 0 0em rgba(0,115,119, 0.2), 0em 2.5em 0 0em rgba(0,115,119, 0.5), -1.8em 1.8em 0 0em rgba(0,115,119, 0.7), -2.6em 0em 0 0em #007078, -1.8em -1.8em 0 0em rgba(0,115,119, 0.2);
        }
        87.5% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.2), 1.8em -1.8em 0 0em rgba(0,115,119, 0.2), 2.5em 0em 0 0em rgba(0,115,119, 0.2), 1.75em 1.75em 0 0em rgba(0,115,119, 0.2), 0em 2.5em 0 0em rgba(0,115,119, 0.2), -1.8em 1.8em 0 0em rgba(0,115,119, 0.5), -2.6em 0em 0 0em rgba(0,115,119, 0.7), -1.8em -1.8em 0 0em #007078;
        }
    }
    .loading_text{
        margin-top: 20px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;
        font-size: 24px;
        color: #373D43;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.emptyDataContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    position: fixed;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
    .emptyDataContainer__img{
        width: 200px;
        height: 120px;
    }
    .emptyDataContainer__txt{
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: #373D43;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .emptyDataContainer_subText{
        width: 250px;
        height: 46px;
        /* Mobile/Body Text - Mobile */
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 23px;
        /* or 153% */
        text-align: center;
        letter-spacing: 0.2px;
        color: #6C757D;
    }
    .emptyDataContainer_subTextError{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 23px;
        letter-spacing: 0.2px;
        color: #00767A;
    }
}
.ref-data-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    margin: 5px 15px;
    .data-card{
        width: 100%;
        margin: 5px 0;
        height: 120px;
        background: #FFFFFF;
        box-shadow: 0px 2px 16px rgba(0, 24, 26, 0.04);
        border-radius: 8px;
        flex-direction: column;
        position: relative;
        text-decoration: none;
        cursor: pointer;
        .card-upper-container{
            display: flex;
            flex-direction: row;
            height: auto;
            width: 85%;
            margin: 10px 20px; 
            padding: 10px 0;
            border-bottom: 1px solid#EFF0F1;
            .img-container{
                background: #EFF0F1;
                width: 48px;
                height: 48px;
                border-radius: 50%;
                position: relative;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                span{
                    font-family: 'Open Sans', sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 16px;
                    letter-spacing: 0.2px;
                    color: #007078;
                }
            }
            .desc-container{
                display: flex;
                margin-left: 10px;
                height: 48px;
                width: 200px;
                white-space: nowrap;
                overflow: hidden;
                flex-direction: column;
                h1{
                    font-family: 'Open Sans', sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.2px;
                    color: #373D43;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
                h3{
                    font-family: 'Open Sans', sans-serif;
                    font-style: italic;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 20px;
                    letter-spacing: 0.2px;
                    color: #6C757D;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .card-lower-container{
            display: flex;
            flex-direction: row;
            height: auto;
            width: 80%;
            margin: 10px 20px; 
            span{
                margin-left: 10px;
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 20px;
                letter-spacing: 0.2px;
                color: #373D43;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        .card-arrow{
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translate(0,-50%);
        }
    }
}

@media screen and (min-width: 768px) { //desktop view
    .ref-data-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        margin: 12px 0;
        .data-card{
            width: 100%;
            margin: 8px 0 0;
            height: 112px;
            text-decoration: none;
            background: #FFFFFF;
            box-shadow: 0px 2px 16px rgba(0, 24, 26, 0.04);
            border-radius: 8px;
            flex-direction: row;
            position: relative;
            cursor: pointer;
            .card-upper-container{
                display: flex;
                flex-direction: row;
                height: auto;
                width: 70%;
                margin: 6px 120px; 
                padding: 10px 0 4px 0;
                border-bottom: 1px solid#EFF0F1;
                .img-container{
                    background: #EFF0F1;
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    display: flex;
                    left: 20px;
                    margin-right: 20px;
                    position: absolute;
                    justify-content: center;
                    align-items: center;
                    span{
                        font-family: 'Open Sans', sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 26px;
                        line-height: 16px;
                        letter-spacing: 0.2px;
                        color: #007078;
                    }
                }
                .desc-container{
                    display: flex;
                    margin-left: 0;
                    height: 48px;
                    width: 200px;
                    white-space: nowrap;
                    overflow: hidden;
                    flex-direction: column;
                    h1{
                        font-family: 'Open Sans', sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 26px;
                        letter-spacing: 0.2px;
                        color: #373D43;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    h3{
                        font-family: 'Open Sans', sans-serif;
                        font-style: italic;
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 22px;
                        letter-spacing: 0.2px;
                        color: #6C757D;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
            .card-lower-container{
                display: flex;
                flex-direction: row;
                height: auto;
                width: 80%;
                margin: 10px 120px; 
                span{
                    margin-left: 8px;
                    font-family: 'Open Sans', sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 20px;
                    letter-spacing: 0.2px;
                    color: #373D43;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
            .card-arrow{
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translate(0,-50%);
            }
        }
        .data-card:hover{
            box-shadow: 0 2px 16px rgba(0, 24, 26, 0.12);
        }
    }
    .emptyDataContainer{
        .emptyDataContainer_subText{
            width: 436px;
            height: 22px;
            /* Desktop/Body - Desktop */
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            /* identical to box height, or 157% */
            text-align: center;
            letter-spacing: 0.2px;
            /* Neutral/Typography Medium */
            color: #6C757D;
        }
    }
}

@media screen and (min-width: 1200px) {
    .ref-data-container{
        .data-card{
            .card-upper-container{
                width: 75%;
            }
        }
    }
}