*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.back-container{
    display: flex;
    margin: 20px 20px;
    align-items: center;
    cursor: pointer;
    .link{
        display: flex;
        text-decoration: none;
    }
    .reference-route-title{
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: #007377;
        margin-left: 10px;
    }
}

.preferred-btn-container{
    display: flex;
    align-items: flex-start;
    margin: 20px 0;
    height: auto;
    padding: 10px 20px;
    .preferred-btn{
        width: 100%;
        height: 38px;
        font-size: 14px;
        font-weight: 600;
        background: #007078;
        border-radius: 4px;
        outline: none;
        border: none;
        cursor: pointer;
        color: #FFFFFF;
        font-family: 'Open Sans', sans-serif;
        img{
            position: relative;
            right: 11.64px;
            top: 2px;
           
          }
    }   
}


.contact{
    display: none;
    text-decoration: none;
}
@media screen and (min-width: 768px) {
    .card{
    width: 704px;
    margin: 0 auto 50px;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    .back-container{
        width: 704px;
        margin: 24px auto 108px;
        .reference-route-title{
            font-size: 15px;
            font-weight: 600;
            line-height: 23px;
        }
    }

    .preferred-btn-container{
  
        display: none;
    }
}
@media screen and (min-width: 1024px) {
    .card{
    width: 864px;
    }
    .back-container{
        width: 864px;
        margin: 24px auto 108px;
    }

    .preferred-btn-container{
  
        display: none;
    }

}

