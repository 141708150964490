.container{
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    display: flex;
    background-color: #FFFFFF;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
    height: 385px;
    // border:dotted;
    // max-width: 864px;
    width: 90vw;
    max-width: 606px;
    box-shadow: 0px 2px 16px rgba(0, 24, 26, 0.04);
    border-radius: 4px;
    margin-top: 89px;

    & div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 80%;
        
        .expired-image{
            margin-top: 60px;
            margin-bottom: 20px;
            // width: 160px;
            // height: 169px;
            width: 90px;
            height: 95px;
        }
        .header{
            /* Desktop/H2 - Desktop */

            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 26px;
            line-height: 34px;
            /* identical to box height, or 131% */

            letter-spacing: 0.2px;

            /* Neutral/Typography Dark */

            color: #373D43;
            margin-bottom: 16px;
        }
        .paragraph{
            /* Desktop/Body - Desktop */

            font-family: 'Open Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            /* or 157% */

            text-align: center;
            letter-spacing: 0.2px;

            /* Neutral/Typography Medium */

            color: #6C757D;
            width: 90%;
            margin-bottom: 30px;
        }
    }
}
