*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.ref-container{
    align-items: stretch;
    .ref-wrapper{
        flex-direction: column;
        align-items: stretch;
        width: 100%;
        padding: 0;
    }
}
@media screen and (min-width: 768px) {
    .ref-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
        position: relative;

        .ref-wrapper{
            display: flex;
            padding: 0 0;
            flex-direction: column;
            position: absolute;
            top: 50%;
            width: 70%;
        }
    }
}