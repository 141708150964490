*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}
.hidden{
    display: none;
}
    .search-container{
        display: flex;
        margin: 0 15px;
        width: auto;
        height: auto;
        position: relative;
        .search-glass{
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translate(0,-50%);
        }
        .search-input{
            width: calc(100% - 38px);
            height: 38px;
            border-radius: 4px;
            outline: none;
            border: none;
            padding-left: 40px;
            color: #373D43;
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.2px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            border: 1px solid #DEE0E2;
        }
        .search-input:hover {
            border: 1px solid #007078;
        }
        .search-input::placeholder{
            color: rgb(128, 125, 125);
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.2px;
        }
        .search-input:focus{
            border: 1px solid #007078;
        }
        .active-filter-container{
            width: 38px;
            height: 38px;
            background-color: #FFFFFF;
            border: 1px solid #DEE0E2;
            margin-left: 10px;
            border-radius: 4px;
            position: relative;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            & span{
                display: none;
            }
            .filter-btn-icon{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                pointer-events: none;
            }
            .number-of-filters{
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                background-color: #007078;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                right: 0;
                transform: translate(50%,-50%);
                color: #FFFFFF;
                font-size: 14px;
                font-family: 'Open Sans';
                font-weight: 600;
            }
        }
        .filter-container{
            width: 38px;
            height: 38px;
            background-color: #FFFFFF;
            margin-left: 10px;
            border-radius: 4px;
            position: relative;
            border: 1px solid #DEE0E2;
            cursor: pointer;
            & span{
                display: none;
            }
            .filter-btn-icon{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                pointer-events: none;
            }
            .number-of-filters{
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                background-color: #007078;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                right: 0;
                transform: translate(50%,-50%);
                color: #FFFFFF;
                font-size: 14px;
                font-family: 'Open Sans';
                font-weight: 600;
            }
        }
        .filter-container:hover{
            border: 1px solid #007078;
        }
    }
@media screen and (min-width: 768px) {
    .search-container{
        display: flex;
        width: 100%;
        margin-bottom: 5px;
        margin-left: 0;
        height: auto;
        position: relative;
        .search-glass{
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translate(0,-50%);
        }
        .search-input{
            width: calc(100% - 94px);
            height: 38px;
            border-radius: 4px;
            outline: none;
            border: none;
            padding-left: 40px;
            color: #373D43;
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.2px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            border: 1px solid #DEE0E2;
        }
        .search-input::placeholder{
            color: rgb(128, 125, 125);
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.2px;
        }
        .search-input:focus{
            border: 1px solid #007078;
        }
        .active-filter-container{
            display: flex;
            flex-direction: row;
            width: 94px;
            height: 38px;
            background-color: #FFFFFF;
            margin-left: 10px;
            border-radius: 4px;
            position: relative;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: 1px solid #007078;
            & span{
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 22px;
                margin-left: 4px;
                display: flex;
                align-items: center;
                letter-spacing: 0.2px;
                text-transform: uppercase;
                color: #6C757D;
            }
            .filter-btn-icon{
                position: relative;
                top: 0;
                left: 0;
                transform: none;
                pointer-events: none;
            }
            .number-of-filters{
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                background-color: #007078;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                right: 0;
                transform: translate(50%,-100%);
                color: #FFFFFF;
                font-size: 14px;
                font-family: 'Open Sans';
                font-weight: 600;
            }
        }
        .filter-container{
            display: flex;
            flex-direction: row;
            width: 94px;
            height: 38px;
            background-color: #FFFFFF;
            margin-left: 10px;
            border-radius: 4px;
            position: relative;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            & span{
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 22px;
                margin-left: 4px;
                display: flex;
                align-items: center;
                letter-spacing: 0.2px;
                text-transform: uppercase;
                color: #6C757D;
            }
            .filter-btn-icon{
                position: relative;
                transform: none;
                top: auto;
                left: auto;
                pointer-events: none;
            }
            .number-of-filters{
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                background-color: #007078;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                right: 0;
                transform: translate(50%,-100%);
                color: #FFFFFF;
                font-size: 14px;
                font-family: 'Open Sans';
                font-weight: 600;
            }
        }
    }
}
