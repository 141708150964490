*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.preferred-btn-container{
    display: none;
}

.header-card{
    display: flex;
    height: 150px;
    margin-top: 75px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 16px rgba(0, 24, 26, 0.04);
    position: relative;
    align-items: center;
    overflow: visible;
    .surgeon-name{
        margin: 0px;
    }
    .surgeon-image{
        display: flex;
        width: 100px;
        height: 100px;
        background: #F2F2F2;
        border: 8px solid #FFFFFF;
        border-radius: 50%;
        position: absolute;
        top: -30%;
        left: 30px;
        justify-content: center;
        align-items: center;
        .initials{
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 40px;
            line-height: 44px;
            letter-spacing: 0.2px;
            color: #00767A;
        }
    }

    .surgeon-details{
        display: flex;
        flex-direction: column;
        width: auto;
        margin-left: 30px;
        margin-top: 50px;
        h1{
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: 0.2px;
            color: #373D43;
        }
        h3{
            font-family: 'Open Sans', sans-serif;
            font-style: italic;
            font-weight: normal;
            font-size: 15px;
            line-height: 23px;
            letter-spacing: 0.2px;
            color: #6C757D;
        }
        & .location-container{
            display: flex;

            h3{
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 20px;
                letter-spacing: 0.2px;
                color: #373D43;
                margin-left: 10px;
            }
        }
    }
}

@media screen and (min-width:768px) {
    .header-card{
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        height: auto;
        .surgeon-name{
            margin: 104px 0px 4px 0px;
        }
        .surgeon-image{
            width: 160px;
            height: 160px;
            top: -81px;
        }
        .surgeon-details{
            margin-top: 0;
            h1{
                font-size: 26px;
                line-height: 34px;
                margin-top: 104px;
            }
            h3{
                font-size: 18px;
                line-height: 26px;
                margin: 4px 0;
            }
            .preferred-btn-container{
                .preferred-btn{
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 22px;
                    img{
                      position: relative;
                      right: 11.64px;
                      top: 2px;
                     
                    }
                    
                }
            }
            & .location-container{
                h3{
                    font-family: "Open Sans";
                    font-weight: 400;
                    font-size:18px;
                    line-height: 26px;
                }

        }
        }


    }
    .preferred-btn-container{
        // visibility: visible;
        display: flex;
        align-items: flex-start;
        height: auto;
        width: 240px;
        margin:15px 0 32px;
        padding:0;
        .preferred-btn{
            width: 100%;
            height: 38px;
            background: #007078;
            border-radius: 4px;
            outline: none;
            border: none;
            cursor: pointer;
            color: #FFFFFF;
            font-family: 'Open Sans', sans-serif;
        }   
        .email-btn:hover{
            background: #004c52;
        }
    }

}