
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;1,400&display=swap');
body{
    background-color: #f5f5f5;
    overflow-x: hidden;
}
.links{
    margin-left: 20px;
}
.loading{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 80vh;
    position: relative;
    .loader {
        margin: 50px auto;
        font-size: 12px;
        width: 1.25em;
        height: 1.25em;
        border-radius: 50%;
        position: absolute;
        bottom: 52%;
        -webkit-animation: load5 1.1s infinite ease;
        animation: load5 1.1s infinite ease;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
      }
      @-webkit-keyframes load5 {
        0%,
        100% {
          box-shadow: 0em -2.6em 0em 0em #007078, 1.8em -1.8em 0 0em rgba(0,115,119, 0.2), 2.5em 0em 0 0em rgba(0,115,119, 0.2), 1.75em 1.75em 0 0em rgba(0,115,119, 0.2), 0em 2.5em 0 0em rgba(0,115,119, 0.2), -1.8em 1.8em 0 0em rgba(0,115,119, 0.2), -2.6em 0em 0 0em rgba(0,115,119, 0.5), -1.8em -1.8em 0 0em rgba(0,115,119, 0.7);
        }
        12.5% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.7), 1.8em -1.8em 0 0em #007078, 2.5em 0em 0 0em rgba(0,115,119, 0.2), 1.75em 1.75em 0 0em rgba(0,115,119, 0.2), 0em 2.5em 0 0em rgba(0,115,119, 0.2), -1.8em 1.8em 0 0em rgba(0,115,119, 0.2), -2.6em 0em 0 0em rgba(0,115,119, 0.2), -1.8em -1.8em 0 0em rgba(0,115,119, 0.5);
        }
        25% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.5), 1.8em -1.8em 0 0em rgba(0,115,119, 0.7), 2.5em 0em 0 0em #007078, 1.75em 1.75em 0 0em rgba(0,115,119, 0.2), 0em 2.5em 0 0em rgba(0,115,119, 0.2), -1.8em 1.8em 0 0em rgba(0,115,119, 0.2), -2.6em 0em 0 0em rgba(0,115,119, 0.2), -1.8em -1.8em 0 0em rgba(0,115,119, 0.2);
        }
        37.5% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.2), 1.8em -1.8em 0 0em rgba(0,115,119, 0.5), 2.5em 0em 0 0em rgba(0,115,119, 0.7), 1.75em 1.75em 0 0em #007078, 0em 2.5em 0 0em rgba(0,115,119, 0.2), -1.8em 1.8em 0 0em rgba(0,115,119, 0.2), -2.6em 0em 0 0em rgba(0,115,119, 0.2), -1.8em -1.8em 0 0em rgba(0,115,119, 0.2);
        }
        50% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.2), 1.8em -1.8em 0 0em rgba(0,115,119, 0.2), 2.5em 0em 0 0em rgba(0,115,119, 0.5), 1.75em 1.75em 0 0em rgba(0,115,119, 0.7), 0em 2.5em 0 0em #007078, -1.8em 1.8em 0 0em rgba(0,115,119, 0.2), -2.6em 0em 0 0em rgba(0,115,119, 0.2), -1.8em -1.8em 0 0em rgba(0,115,119, 0.2);
        }
        62.5% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.2), 1.8em -1.8em 0 0em rgba(0,115,119, 0.2), 2.5em 0em 0 0em rgba(0,115,119, 0.2), 1.75em 1.75em 0 0em rgba(0,115,119, 0.5), 0em 2.5em 0 0em rgba(0,115,119, 0.7), -1.8em 1.8em 0 0em #007078, -2.6em 0em 0 0em rgba(0,115,119, 0.2), -1.8em -1.8em 0 0em rgba(0,115,119, 0.2);
        }
        75% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.2), 1.8em -1.8em 0 0em rgba(0,115,119, 0.2), 2.5em 0em 0 0em rgba(0,115,119, 0.2), 1.75em 1.75em 0 0em rgba(0,115,119, 0.2), 0em 2.5em 0 0em rgba(0,115,119, 0.5), -1.8em 1.8em 0 0em rgba(0,115,119, 0.7), -2.6em 0em 0 0em #007078, -1.8em -1.8em 0 0em rgba(0,115,119, 0.2);
        }
        87.5% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.2), 1.8em -1.8em 0 0em rgba(0,115,119, 0.2), 2.5em 0em 0 0em rgba(0,115,119, 0.2), 1.75em 1.75em 0 0em rgba(0,115,119, 0.2), 0em 2.5em 0 0em rgba(0,115,119, 0.2), -1.8em 1.8em 0 0em rgba(0,115,119, 0.5), -2.6em 0em 0 0em rgba(0,115,119, 0.7), -1.8em -1.8em 0 0em #007078;
        }
      }
      @keyframes load5 {
        0%,
        100% {
          box-shadow: 0em -2.6em 0em 0em #007078, 1.8em -1.8em 0 0em rgba(0,115,119, 0.2), 2.5em 0em 0 0em rgba(0,115,119, 0.2), 1.75em 1.75em 0 0em rgba(0,115,119, 0.2), 0em 2.5em 0 0em rgba(0,115,119, 0.2), -1.8em 1.8em 0 0em rgba(0,115,119, 0.2), -2.6em 0em 0 0em rgba(0,115,119, 0.5), -1.8em -1.8em 0 0em rgba(0,115,119, 0.7);
        }
        12.5% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.7), 1.8em -1.8em 0 0em #007078, 2.5em 0em 0 0em rgba(0,115,119, 0.2), 1.75em 1.75em 0 0em rgba(0,115,119, 0.2), 0em 2.5em 0 0em rgba(0,115,119, 0.2), -1.8em 1.8em 0 0em rgba(0,115,119, 0.2), -2.6em 0em 0 0em rgba(0,115,119, 0.2), -1.8em -1.8em 0 0em rgba(0,115,119, 0.5);
        }
        25% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.5), 1.8em -1.8em 0 0em rgba(0,115,119, 0.7), 2.5em 0em 0 0em #007078, 1.75em 1.75em 0 0em rgba(0,115,119, 0.2), 0em 2.5em 0 0em rgba(0,115,119, 0.2), -1.8em 1.8em 0 0em rgba(0,115,119, 0.2), -2.6em 0em 0 0em rgba(0,115,119, 0.2), -1.8em -1.8em 0 0em rgba(0,115,119, 0.2);
        }
        37.5% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.2), 1.8em -1.8em 0 0em rgba(0,115,119, 0.5), 2.5em 0em 0 0em rgba(0,115,119, 0.7), 1.75em 1.75em 0 0em #007078, 0em 2.5em 0 0em rgba(0,115,119, 0.2), -1.8em 1.8em 0 0em rgba(0,115,119, 0.2), -2.6em 0em 0 0em rgba(0,115,119, 0.2), -1.8em -1.8em 0 0em rgba(0,115,119, 0.2);
        }
        50% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.2), 1.8em -1.8em 0 0em rgba(0,115,119, 0.2), 2.5em 0em 0 0em rgba(0,115,119, 0.5), 1.75em 1.75em 0 0em rgba(0,115,119, 0.7), 0em 2.5em 0 0em #007078, -1.8em 1.8em 0 0em rgba(0,115,119, 0.2), -2.6em 0em 0 0em rgba(0,115,119, 0.2), -1.8em -1.8em 0 0em rgba(0,115,119, 0.2);
        }
        62.5% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.2), 1.8em -1.8em 0 0em rgba(0,115,119, 0.2), 2.5em 0em 0 0em rgba(0,115,119, 0.2), 1.75em 1.75em 0 0em rgba(0,115,119, 0.5), 0em 2.5em 0 0em rgba(0,115,119, 0.7), -1.8em 1.8em 0 0em #007078, -2.6em 0em 0 0em rgba(0,115,119, 0.2), -1.8em -1.8em 0 0em rgba(0,115,119, 0.2);
        }
        75% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.2), 1.8em -1.8em 0 0em rgba(0,115,119, 0.2), 2.5em 0em 0 0em rgba(0,115,119, 0.2), 1.75em 1.75em 0 0em rgba(0,115,119, 0.2), 0em 2.5em 0 0em rgba(0,115,119, 0.5), -1.8em 1.8em 0 0em rgba(0,115,119, 0.7), -2.6em 0em 0 0em #007078, -1.8em -1.8em 0 0em rgba(0,115,119, 0.2);
        }
        87.5% {
          box-shadow: 0em -2.6em 0em 0em rgba(0,115,119, 0.2), 1.8em -1.8em 0 0em rgba(0,115,119, 0.2), 2.5em 0em 0 0em rgba(0,115,119, 0.2), 1.75em 1.75em 0 0em rgba(0,115,119, 0.2), 0em 2.5em 0 0em rgba(0,115,119, 0.2), -1.8em 1.8em 0 0em rgba(0,115,119, 0.5), -2.6em 0em 0 0em rgba(0,115,119, 0.7), -1.8em -1.8em 0 0em #007078;
        }
    }
    .loading__text{
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #373D43;
    }
}
