*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.expired-link-container{
    position: relative;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & span:first-child{
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 23px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #373D43;
    }
    & span:last-child{
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 23px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #00767A;
        cursor: pointer;
    }
}

@media screen and (max-width: 768px) {
    .expired-link-container{
        position: relative;
        bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        & span:first-child{
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 23px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #373D43;
        }
        & span:last-child{
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 23px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #00767A;
            cursor: pointer;
        }
    }
}