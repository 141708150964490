*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1;
    background-color: rgba(0,0,0,0.2);
    pointer-events: all;
    cursor: default;
}
.overlay-hidden{
    display: none;
}
.ref-modal-hidden{
    position: fixed;
    transform: translateX(100%);
    z-index: 1;
    width: 90vw;
    height: 100vh;
    bottom: 0;
    right: 0;
    box-shadow: 0px 0px 20px rgba(0, 75, 80, 0.07);
    border-radius: 8px 0 0 8px;
    background-color: #FFFFFF;
    transition: transform 1s ease-in-out;
}
.ref-modal{
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 75, 80, 0.07);
    border-radius: 8px 0 0 8px;
    width: 90vw;
    height: 100vh;
    bottom: 0;
    right: 0;
    position: fixed;
    transform: translateX(0);
    cursor: default;
    pointer-events: all;
    z-index: 1;
    transition: transform 1s ease-in-out;
}
.title-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #EFF0F1;
    position: relative;
    .filter-modal-icon{
        margin-left: 30px;
    }
    .filter-title{
        margin-left: 5px;
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        line-height: 26px;
        letter-spacing: 0.2px;
        color: #373D43;
        font-family: 'Open Sans', sans-serif;
    }
    .close-x{
        color: #7D858C;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 20px;
        cursor: pointer;
    }
}
.speciality-container{
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    .speciality-title{
        margin-left: 30px;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0.2px;
        text-transform: uppercase;
        color: #6C757D;
        font-family: 'Open Sans', sans-serif;
    }
    .speciality-list{
        display: flex;
        flex-direction: column;
        margin-left: 30px;
        .checkbox-container{
            list-style: none;
            display: flex;
            align-items: center;
            margin: 5px 0;
            .checkbox-label{
                cursor: pointer;
                display: flex;
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 23px;
                letter-spacing: 0.2px;
                color: #373D43;
                align-items: center;
            }
            .checkbox-label:before{
                content: url('../../../shared/images/unchecked-box.svg');
                width: 1em;
                height: 1em;
                margin-right: 12px;
                // border-radius: 2px;
                // border: .05em solid #AEB3B7;
                // background-image: url('../../../shared/images/unchecked-box.svg');
            }   
            .checkbox-input{
                cursor: pointer;
                opacity: 1;
                visibility: hidden;
                // visibility: visible;
                position: absolute;
            }
            .checkbox-input:after{
                content: '';
            }
            .checkbox-input:hover:after{
                content: url('../../../shared/images/unchecked-box-hover.svg');
                position: absolute;
                bottom: 0;
                top: -1px;
                visibility: visible;
            }
            .checkbox-input:checked{
                content: url('../../../shared/images/checked-hover.svg');
            }
            .checkbox-input:checked + .checkbox-label::before{
                content: url('../../../shared/images/checked-box.svg');
                // content: "\2713";
                // background-color: #4DA1A8;
                // color: white;
                // display: flex;
                // justify-content: center;
                // align-items: center;
                // background-image: url('../../../shared/images/checked-box.svg');
            }
            .checkbox-input:checked + .checkbox-label:hover::before{
                content: url('../../../shared/images/checked-hover.svg');
                // content: "\2713";
                // background-color: #4DA1A8;
                // color: white;
                // display: flex;
                // justify-content: center;
                // align-items: center;
                // background-image: url('../../../shared/images/checked-box.svg');
            }
        }
    }
}
.exit-container{
    display: flex;
    height: auto;
    position: absolute;
    bottom: 20px;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    .close-modal{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        line-height: 22px;
        color: #6C757D;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        cursor: pointer;
        margin-right: 24px;
        background: none;
        border-radius: 4px;
        padding: 8px 16px;
    }
    .close-modal:hover{
        background: #F7F7F8;
        border-radius: 4px;
        padding: 8px 16px;
    }
    .apply-btn{
        font-weight: 600;
        font-style: normal;
        font-family: 'Open Sans';
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.2px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        background: #007078;
        border-radius: 4px;
        color: white;
        border: none;
        outline: none;
        cursor: pointer;
        text-transform: uppercase;
        margin-right: 24px;
    }
    .apply-btn:hover{
        background: #004c52;
    }
}
.test{
    background-color: yellow;
    .hi{
        background-color: red;
    }
    .bye{
        background-color: blue;
    }
    .apply-btn:hover{
        background: #004A4D;
    }
}
@media screen and (min-width: 768px) {
    .overlay{
        display: none;
    }
    .ref-modal-hidden{
        display: none;
    }
        .ref-modal{
            display: flex;
            flex-direction: column;
            background-color: #FFFFFF;
            border-radius: 8px;
            height: 397px;
            width: 466px;
            bottom: 0;
            cursor: default;
            top: 110%;
            right: -4px;
            position: absolute;
            transition: none;
            transform: translateX(0);
            .title-container{
                display: none;
                align-items: center;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 70px;
                border-bottom: 1px solid #EFF0F1;
                position: relative;
                .filter-modal-icon{
                    margin-left: 30px;
                }
                .filter-title{
                    margin-left: 5px;
                    font-size: 18px;
                    font-weight: 600;
                    font-style: normal;
                    line-height: 26px;
                    letter-spacing: 0.2px;
                    color: #373D43;
                    font-family: 'Open Sans', sans-serif;
                }
                .close-x{
                    color: #7D858C;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    right: 20px;
                    cursor: pointer;
                }
            }
            .speciality-container{
                display: flex;
                margin: 20px 0;
                flex-direction: column;
                .speciality-title{
                    margin-left: 24px;
                    font-size: 16px;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-style: normal;
                    line-height: 24px;
                    letter-spacing: 0.2px;
                    color: #6C757D;
                    font-family: 'Open Sans', sans-serif;
                }
                .speciality-list{
                    display: flex;
                    flex-direction: column;
                    height: 275px;
                    flex-wrap: wrap;
                    margin: 5px 24px;
                    // .checkbox-container{
                    //     list-style: none;
                    //     display: flex;
                    //     align-items: center;
                    //     margin: 5px 0;
                    //     label{
                    //         cursor: pointer;
                    //         display: flex;
                    //         font-family: 'Open Sans', sans-serif;
                    //         font-style: normal;
                    //         font-weight: normal;
                    //         font-size: 15px;
                    //         line-height: 23px;
                    //         letter-spacing: 0.2px;
                    //         color: #373D43;
                    //         align-items: center;
                    //     }
                    //     label:before{
                    //         content: "";
                    //         width: 1em;
                    //         height: 1em;
                    //         border-radius: 2px;
                    //         margin-right: .5em;
                    //         border: .05em solid #AEB3B7;
                    //     }   
                    //     input[type=checkbox]{
                    //         cursor: pointer;
                    //         opacity: 0;
                    //         position: absolute;
                    //     }
                    //     input[type=checkbox]:checked + label::before{
                    //         content: "\2714";
                    //         background-color: #4DA1A8;
                    //         color: white;
                    //         display: flex;
                    //         justify-content: center;
                    //         align-items: center;
                    //     }
                    // }
                }
            }
            .exit-container{
                display: flex;
                height: auto;
                position: absolute;
                bottom: 20px;
                width: 100%;
                justify-content: flex-end;
                align-items: center;
                // .close-modal{
                //     font-family: 'Open Sans', sans-serif;
                //     font-style: normal;
                //     font-weight: 600;
                //     font-size: 14px;
                //     line-height: 22px;
                //     color: #009CA6;
                //     text-transform: uppercase;
                //     letter-spacing: 0.2px;
                //     cursor: pointer;
                //     margin-right: 30px;
                // }
                // .apply-btn{
                //     display: flex;
                //     flex-direction: row;
                //     justify-content: center;
                //     align-items: center;
                //     width: 140px;
                //     height: 38px;
                //     white-space: nowrap;
                //     background: rgba(0, 156, 166, 0.1);
                //     border-radius: 4px;
                //     color: #009CA6;
                //     border: none;
                //     outline: none;
                //     cursor: pointer;
                //     text-transform: uppercase;
                //     margin-right: 20px;
                // }
            }
        }
    }