*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.hidden{
    display: none;
}
.contact-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #FFFFFF;
    margin-top: 10px;
    height: 100px;
    padding: 0 30px;
    box-shadow: 0px 2px 16px rgba(0, 24, 26, 0.04);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    .email-container{
        display: flex;
        flex-direction: column;
        .preferred{
            font-family: 'Open Sans', sans-serif;
            font-style: italic;
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: #6C757D;
        }
        h6{
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: #6C757D;
        }
        .contact-link{
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 23px;
            letter-spacing: 0.2px;
            color: #00767A;
            cursor: pointer;
            padding: 8px 0px 0px 0px;
        }
    }
    .phone-container{
        display: flex;
        flex-direction: column;
        .preferred{
            font-family: 'Open Sans', sans-serif;
            font-style: italic;
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: #6C757D;
        }
        h6{
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: #6C757D;
        }
        .contact-link{
            display: inline;
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 23px;
            letter-spacing: 0.2px;
            color: #00767A;
            cursor: pointer;
            padding: 8px 0px 0px 0px;
        }
    }
}

@media screen and (min-width: 768px) {
   .contact-container{
        margin-top: 0;
        box-shadow: none;
        flex-direction: row;
        justify-content: start;
        .email-container{
            width: 50%;       
            border-top: 1px solid #eff0f1;
            padding-top: 24px;
        }
        .phone-container{
            border-top: 1px solid #eff0f1;
            padding-top: 24px;
            width: 50%;
        }
   } 
  
}