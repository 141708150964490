*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.nav-header{
    display: flex;
    background-color: #FFFFFF;
    width: 100vw;
    height: 48px;
    justify-content: flex-start;
    align-items: center;
    .title-container{
        img {
            width: 85px;
            height: 30px;
            margin: 18px;

            
        }

    }
}  
@media screen and (min-width: 768px){
    .nav-header{
        flex-direction: row;
        height: 56px;
        .title-container{
            img{                
                width: 102px;
                height: 36px;
                margin-left: 140px;
            }
        }
    }
    .nav-header:hover{
        cursor: pointer;
    }
}  
