*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.ref-title-container{
    display: flex;
    margin: 20px 15px;
    .ref-title{
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.2px;
        color: #007078;
    }
}  

@media screen and (min-width: 768px) {
    .ref-title-container{
        display: flex;
        margin: 24px 0 16px 0;
        .ref-title{
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-size: 26px;
            line-height: 26px;
            letter-spacing: 0.2px;
            color: #007078;
        }
    }  
}